<template>
  <div
    :class="{
      'container-fluid px-lg-5 mb-3': true,
      'margin-top-0421': this.$route.query.ismobileapp == undefined,
    }"
  >
    <div class="row">
      <div class="col-md-6 d-flex order-2 order-md-1">
        <div class="align-self-center text-center text-md-start">
          <h3 class="text-primary">
            <i class="fa-handshake fas text-secondary rounded-circle"></i>
            Trepr Partner Introduction
          </h3>
          <div class="lead text-justify">
            Trepr (Trip Representative) is the first ONLINE P2P TRANSPORTATION PLATFORM and a
            trusted community marketplace for people to list, discover, and assign air travelers for
            their transportation needs around the world. Trepr connects people all over the world to
            exchange their travel plan and invoke travel services such as flight companionship,
            courier delivery, and shopping concierge, safely, cheaply and promptly respectively
            anywhere in the world by coordinating with travelers who travel in the same route. By
            registering with Trepr, you will be able to refer customers and earn referral rewards
            for every qualified leads. Trepr will also help drive traffic to your platform with huge
            saving on flight bookings and reward your customers with cash back options. We have
            several agents already working with Trepr to take their travel business to new heights.
            So, what are you waiting for? To get your agency registered, fill the form below and in
            case of any further questions, feel free to contact our team to discover how you can
            grow your business with us.
          </div>
        </div>
      </div>
      <div class="col-md-6 order-1 order-md-2">
        <div class="row text-center">
          <div class="col-6 bg-primary text-white">
            <h1>WHAT DO WE PROVIDE?</h1>
            <ul class="list-unstyled">
              <li>Travel companionship</li>
              <li>Courier delivery</li>
              <li>Shopping concierge</li>
              <li>All via flight passengers</li>
            </ul>
          </div>
          <div class="col-6">
            <h1 class="text-secondary">WHY DOES THIS MATTER</h1>
            <ul class="list-unstyled">
              <li>Reliable flight assistance for ease of travel</li>
              <li>Low-cost and guaranteed</li>
              <li>Delivery of unavailable or expensive products from overseas</li>
            </ul>
          </div>
        </div>
        <div class="row bg-info pt-1">
          <div class="col-8">
            <h3 class="text-secondary">Benefits to Agency</h3>
            <ul class="list-unstyled text-white">
              <li>Refer more bookings to agency</li>
              <li>Percentage cut on every match made</li>
              <li>Marketing benefits such as cash back</li>
              <li>Referral reward for shared itineraries</li>
            </ul>
          </div>
          <div class="col-4">
            <img src="/img/partner/parner2.webp" alt="Benefits to Agency" class="img-fluid" />
          </div>
        </div>
        <div class="row bg-secondary">
          <div class="col-4">
            <img
              src="/img/partner/consumer.jpg"
              alt="Benefits to customer"
              class="img-fluid mt-3"
            />
          </div>
          <div class="col-8 text-end">
            <h3 class="text-primary">Benefits to customer</h3>
            <ul class="list-unstyled text-white">
              <li>Earn back up to 75% of light expenses by becoming travellers</li>
              <li>A trusted marketplace for seekers and providers</li>
              <li>Safe companionship for overseas travel</li>
              <li>Cheap courier and prompt concierge services</li>
              <li>Insurance on products and packages shipped</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="personalWrapper">
          <ErrorFormField :msgArray="errors.common" v-if="errors.common !== undefined" />
          <h1 class="text-primary text-center">Personal Detail</h1>
          <div class="row mb-3">
            <div class="col-md-12">
              <fieldset class="border border-info p-2">
                <legend class="float-none w-auto text-info">Login information</legend>
                <div class="row">
                  <div class="form-floating mb-3 col-lg-3 col-md-6 offset-lg-3">
                    <input
                      type="number"
                      class="form-control"
                      id="pdMobile"
                      placeholder="Mobile"
                      maxlength="20"
                      minlength="3"
                    />
                    <label for="pdMobile" class="label-require">Mobile</label>
                    <ErrorFormField
                      :msgArray="errors.pdMobile"
                      v-if="errors.pdMobile !== undefined"
                    />
                  </div>
                  <div class="form-floating mb-3 col-lg-3 col-md-6">
                    <input
                      type="email"
                      class="form-control"
                      id="pdEmail"
                      placeholder="Email"
                      maxlength="255"
                    />
                    <label for="pdEmail" class="label-require">Email</label>
                    <ErrorFormField
                      :msgArray="errors.pdEmail"
                      v-if="errors.pdEmail !== undefined"
                    />
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="row">
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="pdFirstName"
                placeholder="First name"
                maxlength="20"
              />
              <label for="pdFirstName" class="label-require">First name</label>
              <ErrorFormField
                :msgArray="errors.pdFirstName"
                v-if="errors.pdFirstName !== undefined"
              />
            </div>
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="pdLastName"
                placeholder="Last name"
                maxlength="20"
              />
              <label for="pdLastName" class="label-require">Last name</label>
              <ErrorFormField
                :msgArray="errors.pdLastName"
                v-if="errors.pdLastName !== undefined"
              />
            </div>
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <input type="number" class="form-control" id="pdPinCode" placeholder="Pin Code" />
              <label for="pdPinCode" class="label-require">Pin Code</label>
              <ErrorFormField :msgArray="errors.pdPinCode" v-if="errors.pdPinCode !== undefined" />
            </div>

            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="pdResidentialAddress"
                placeholder="Residential Address"
              />
              <label for="pdResidentialAddress" class="label-require">Residential Address</label>
              <ErrorFormField
                :msgArray="errors.pdResidentialAddress"
                v-if="errors.pdResidentialAddress !== undefined"
              />
            </div>
          </div>
          <div class="row"></div>
          <div class="row">
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <input type="text" class="form-control" id="pdAddress2" placeholder="Address 2" />
              <label for="pdResidentialAddress">Address 2</label>
            </div>
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <select
                class="form-select"
                id="pdCountry"
                aria-label="pdCountry"
                @change="pdCountrySelectChange"
              >
                <option selected></option>
                <option v-for="(item, index) in $data.countryList" :value="item.no" :key="index">
                  {{ item.name }}
                </option>
              </select>

              <label for="pdCountry" class="label-require">Country</label>
              <ErrorFormField :msgArray="errors.pdCountry" v-if="errors.pdCountry !== undefined" />
            </div>
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <select
                class="form-select d-none"
                id="pdStateSelect"
                aria-label="pdState"
                @change="pdStateSelectChange"
              >
                <option selected></option>
                <option v-for="(item, index) in $data.stateList" :value="item.no" :key="index">
                  {{ item.name }}
                </option>
              </select>
              <input type="text" class="form-control" id="pdStateInput" placeholder="State" />
              <label class="label-require">State</label>
              <ErrorFormField :msgArray="errors.pdState" v-if="errors.pdState !== undefined" />
            </div>
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <select class="form-select d-none" id="pdCitySelect" aria-label="pdCitySelect">
                <option selected></option>
                <option v-for="(item, index) in $data.cityList" :value="item.no" :key="index">
                  {{ item.name }}
                </option>
              </select>
              <input type="text" class="form-control" id="pdCityInput" placeholder="City" />
              <label for="pdCityInput" class="label-require">City</label>
              <ErrorFormField :msgArray="errors.pdCity" v-if="errors.pdCity !== undefined" />
            </div>
          </div>
          <div class="text-center mt-3">
            <LoadingIcons v-if="$data.ajaxLoad.normal" />
            <button class="btn btn-primary" @click="save" v-else>Sign up</button>
            <a class="btn btn-secondary ms-3" href="/partner/login">Have an account? Login</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorFormField from '../../common/error/ErrorFormField.vue';
import LoadingIcons from '../../common/LoadingIcons.vue';
export default {
  name: 'PartnerSignup',
  components: {
    ErrorFormField,
    LoadingIcons,
  },
  data: function () {
    return {
      personalData: {},
      agencyData: {},
      agencyCountry: '',
      gstData: {},
      ajaxLoad: {},
      errors: {},
      stepper: {},
    };
  },
  methods: {
    async pdCountrySelectChange() {
      var pdCountry = document.getElementById('pdCountry').value; //India : 102
      console.log('pdCountry', pdCountry);
      if (pdCountry == 102) {
        document.getElementById('pdStateInput').classList.add('d-none');
        document.getElementById('pdStateSelect').classList.remove('d-none');
        document.getElementById('pdCityInput').classList.add('d-none');
        document.getElementById('pdCitySelect').classList.remove('d-none');
        let self = this;
        // console.log("state $store ", this.$store.state)
        let apiUrl = process.env.VUE_APP_API_URL;

        await fetch(apiUrl + 'common/get-state', {
          method: 'POST',
          headers: {
            // 'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
          },
          body: 'country=' + pdCountry,
        })
          .then((response) => response.json())
          .then((resData) => {
            // console.log('Success:', resData);
            // let messages = resData.messages
            if (resData.status == 'error') {
              alert('error while geting data ' + resData.message);
              return;
            }
            self.$data.stateList = resData.stateList;
          })
          .catch(function (err) {
            console.log('error', err);
          });
        return;
      }
      document.getElementById('pdStateInput').classList.remove('d-none');
      document.getElementById('pdStateSelect').classList.add('d-none');
      document.getElementById('pdCityInput').classList.remove('d-none');
      document.getElementById('pdCitySelect').classList.add('d-none');
    },
    async pdStateSelectChange() {
      var pdCountry = document.getElementById('pdCountry').value; //India : 102

      if (pdCountry == 102) {
        document.getElementById('pdStateInput').classList.add('d-none');
        document.getElementById('pdStateSelect').classList.remove('d-none');
        document.getElementById('pdCityInput').classList.add('d-none');
        document.getElementById('pdCitySelect').classList.remove('d-none');
        var pdState = document.getElementById('pdStateSelect').value;
        let self = this;
        // console.log("state $store ", this.$store.state)
        let apiUrl = process.env.VUE_APP_API_URL;

        await fetch(apiUrl + 'common/get-city', {
          method: 'POST',
          headers: {
            // 'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
          },
          body: 'country=' + pdCountry + '&state=' + pdState,
        })
          .then((response) => response.json())
          .then((resData) => {
            // console.log('Success:', resData);
            // let messages = resData.messages
            if (resData.status == 'error') {
              alert('error while geting data ' + resData.message);
              return;
            }
            self.$data.cityList = resData.cityList;
          })
          .catch(function (err) {
            console.log('error', err);
          });
        return;
      }
    },
    async save() {
      console.log('nextPage from personal');
      //eslint-disable-next-line
      var data = {};
      this.$data.errors = {};
      var pdFirstName = document.getElementById('pdFirstName').value;
      var pdLastName = document.getElementById('pdLastName').value;
      var pdEmail = document.getElementById('pdEmail').value;
      var pdMobile = document.getElementById('pdMobile').value;
      var pdPinCode = document.getElementById('pdPinCode').value;
      var pdResidentialAddress = document.getElementById('pdResidentialAddress').value;
      var pdCountry = document.getElementById('pdCountry').value; //India : 102
      var pdCountryText =
        document.getElementById('pdCountry').options[
          document.getElementById('pdCountry').selectedIndex
        ].text;
      var pdState = document.getElementById('pdStateInput').value; //India : 02
      var pdCity = document.getElementById('pdCityInput').value; //India : 02
      // var pdState, pdCity;
      if (pdFirstName == '') {
        this.$data.errors.pdFirstName = ['This field is required'];
      }
      if (pdLastName == '') {
        this.$data.errors.pdLastName = ['This field is required'];
      }
      if (pdMobile == '') {
        this.$data.errors.pdMobile = ['This field is required'];
      }
      if (pdEmail == '') {
        this.$data.errors.pdEmail = ['This field is required'];
      } else {
        if (!/\S+@\S+\.\S+/.test(pdEmail)) {
          this.$data.errors.pdEmail = ['This email is invalid'];
        }
      }
      if (pdPinCode == '') {
        this.$data.errors.pdPinCode = ['This field is required'];
      }
      if (pdResidentialAddress == '') {
        this.$data.errors.pdResidentialAddress = ['This field is required'];
      }
      if (pdCountry == '') {
        this.$data.errors.pdCountry = ['This field is required'];
      }
      var pdStateId = 0,
        pdStateText = pdState,
        pdCityId = 0,
        pdCityText = pdCity;
      if (pdCountry == 102) {
        pdState = '';
        pdCity = '';
        //india
        pdStateId = document.getElementById('pdStateSelect').value; //India : 02
        pdCityId = document.getElementById('pdCitySelect').value; //India : 02
        pdStateText =
          document.getElementById('pdStateSelect').options[
            document.getElementById('pdStateSelect').selectedIndex
          ].text;
        pdCityText =
          document.getElementById('pdCitySelect').options[
            document.getElementById('pdCitySelect').selectedIndex
          ].text;
        if (pdStateId == '') {
          this.$data.errors.pdState = ['This field is required'];
        }
        if (pdCityId == '') {
          this.$data.errors.pdCity = ['This field is required'];
        }
      } else {
        if (pdState == '') {
          this.$data.errors.pdState = ['This field is required'];
        }
        if (pdCity == '') {
          this.$data.errors.pdCity = ['This field is required'];
        }
      }

      let self = this;
      if (pdEmail != '' || pdMobile != '') {
        //---check email and mobile number is existed or not----------
        this.$data.ajaxLoad.normal = true;
        // console.log("state $store ", this.$store.state)
        let apiUrl = process.env.VUE_APP_API_URL;
        let objCheckExisted = { pdEmail: pdEmail, pdMobile: pdMobile };
        objCheckExisted.pdFirstName = pdFirstName;
        objCheckExisted.pdLastName = pdLastName;
        objCheckExisted.pdMobile = pdMobile;
        objCheckExisted.pdEmail = pdEmail;
        objCheckExisted.pdPinCode = pdPinCode;
        objCheckExisted.pdResidentialAddress = pdResidentialAddress;
        objCheckExisted.pdAddress2 = document.getElementById('pdAddress2').value;
        objCheckExisted.pdCountry = pdCountry;
        objCheckExisted.pdState = pdState;
        objCheckExisted.pdCity = pdCity;
        objCheckExisted.pdStateId = pdStateId;
        objCheckExisted.pdCityId = pdCityId;
        objCheckExisted.pdStateText = pdStateText;
        objCheckExisted.pdCityText = pdCityText;
        objCheckExisted.pdCountryText = pdCountryText;
        // self.$router.push('/partner/signup-personal-check-email');
        console.log('personal data ', data);
        const formData = Object.keys(objCheckExisted)
          .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(objCheckExisted[key]))
          .join('&');
        await fetch(apiUrl + 'partner/signup-personal', {
          method: 'POST',
          headers: {
            // 'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
          },
          body: formData,
        })
          .then((response) => response.json())
          .then((resData) => {
            // console.log('Success:', resData);

            if (resData.status == 'error') {
              let messages = resData.messages;
              if (messages.pdMobile != undefined) {
                self.$data.errors.pdMobile = [
                  'This  mobile number is existed. Please choose another one',
                ];
                console.log('pdmobile 1111', messages.pdMobile);
                self.$data.errors.pdMobile = messages.pdMobile;
              }
              if (messages.pdEmail != undefined) {
                self.$data.errors.pdEmail = ['This email is existed. Please choose another one'];
                console.log('pdEmail 2222', messages.pdMobile);
                self.$data.errors.pdEmail = messages.pdEmail;
              }
              self.$data.ajaxLoad.normal = false;
              return;
            }
            self.$data.ajaxLoad.normal = false;
            self.$router.push('/partner/signup-personal-check-email'); //partner/signup-personal-check-email
          })
          .catch(function (err) {
            console.log('error', err);
          });
        self.$data.ajaxLoad.normal = false;
        //---end check email and mobile number is existed or not---------
      }
      if (
        Object.keys(this.$data.errors).length !== 0 ||
        Object.getPrototypeOf(this.$data.errors) !== Object.prototype
      ) {
        return;
      }

      // this.$emit('fromChildPersonalNextPage', data);
    },
  },
  async created() {
    document.title = 'Trepr - Partner signup';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'Trepr caters to both travelers and seekers who want travel companions/send packing service for seekers and to travelers who want to earn money during their travel'
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr, trepr partner signup, trepr partner register, Travel companion, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );
    let self = this;
    // console.log("state $store ", this.$store.state)
    let apiUrl = process.env.VUE_APP_API_URL;

    await fetch(apiUrl + 'common/custom-country-list', {
      method: 'GET',
      headers: {
        // 'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
      },
    })
      .then((response) => response.json())
      .then((resData) => {
        // console.log('Success:', resData);
        // let messages = resData.messages
        if (resData.status == 'error') {
          alert('error while geting data ' + resData.message);
          return;
        }
        self.$data.countryList = resData.countryList;
      })
      .catch(function (err) {
        console.log('error', err);
      });
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
@import '../../../assets/scss/new/partner/partner_signup.scss';
</style>
